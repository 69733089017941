import Alpine from "alpinejs"
import Swiper from "swiper"
import { Navigation, Pagination } from "swiper/modules"
// import '~/styles/swiper-bundle.min.css'
import { pauseAllVideos } from "~/utils/video"
// TODO: Migrate to new Vidstack
import "vidstack/styles/defaults.css"
// import 'vidstack/styles/community-skin/video.css'

import { defineCustomElements } from "vidstack/elements"

defineCustomElements()
export function classTypes(sectionIndex) {
  console.log("classTypes", sectionIndex)
  let classTypesData = {
    init() {
      console.log("init classTypes", swiper2)
    },
    classTypesSlideIndex: 0,
    classTypesSlide(value, originatingItem) {
      this.classTypesSlideIndex = value
      // swiper.slideTo(value);
      swiper2.slideTo(value)
      console.log("classTypesSlide", value, originatingItem)
      const itemOffsetLeft = originatingItem.target.offsetLeft
      const scrollNav = document.getElementById(
        `class-types-nav-scroll-${sectionIndex}`
      )
      scrollNav.scrollLeft = itemOffsetLeft - scrollNav.offsetWidth / 3
    },
    centerNavSlide(index) {
      console.log("centerNavSlide", index)
      const scrollNav = document.getElementById(
        `class-types-nav-scroll-${sectionIndex}`
      )
      for (const element of scrollNav.children) {
        if (element.dataset.slideIndex == index) {
          const activeSlideOffsetLeft = element.offsetLeft
          scrollNav.scrollLeft =
            activeSlideOffsetLeft - scrollNav.offsetWidth / 3
        }
      }
    },
  }

  // get classTypesSlide() {
  //   return this.classTypesSlideIndex
  // },
  const swiper2 = new Swiper(`#class-types-slider-${sectionIndex}`, {
    modules: [Navigation, Pagination],
    centerInsufficientSlides: true,
    slidesPerView: 1.1,
    lazyPreloadPrevNext: 10,
    spaceBetween: 16,
    centeredSlides: true,
    centeredSlidesBounds: true,
    navigation: {
      enabled: false,
    },
    breakpoints: {
      768: {
        navigation: {
          enabled: false,
        },
        slidesPerView: 2.3,
        centeredSlides: false,
        centeredSlidesBounds: false,
        grabCursor: true,
        cssMode: false,
      },
      1280: {
        slidesPerView: 3.3,
        centeredSlides: false,
        centeredSlidesBounds: false,
        grabCursor: true,
        cssMode: false,
        slidesPerGroup: 2,
        navigation: {
          nextEl: `.carousel-button-next-${sectionIndex}`,
          prevEl: `.carousel-button-prev-${sectionIndex}`,
        },
      },
    },
  })

  document
    .querySelector(`.carousel-button-next-${sectionIndex}`)
    .addEventListener("click", () => {
      swiper2.slideNext()
    })
  document
    .querySelector(`.carousel-button-prev-${sectionIndex}`)
    .addEventListener("click", () => {
      swiper2.slidePrev()
    })

  swiper2.init()
  swiper2.on("slideChange", function (e) {
    // swiper.slideTo(value);
    swiper2.slideTo(e.activeIndex)
    console.log("classTypesSlide", e.activeIndex, e.previousIndex)
    console.log("slide2 slide change", e)
    classTypesData.classTypesSlideIndex = e.activeIndex
    classTypesData.centerNavSlide(e.activeIndex)
    pauseAllVideos()
  })

  return classTypesData
}

Alpine.data("classTypes", classTypes)

